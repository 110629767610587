import { StyleParamType, createStylesParams } from '@wix/tpa-settings';
import { RssWidgetIconType } from '@wix/communities-blog-client-common';

export default createStylesParams<{
  'widget-iconColor': StyleParamType.Color;
  'widget-iconBackgroundColor': StyleParamType.Color;
  'widget-iconColorNoBackground': StyleParamType.Color;
  'widget-iconBackgroundType': StyleParamType.Number;
}>({
  'widget-iconColor': {
    type: StyleParamType.Color,
  },
  'widget-iconBackgroundColor': {
    type: StyleParamType.Color,
  },
  'widget-iconColorNoBackground': {
    type: StyleParamType.Color,
    getDefaultValue: () => ({
      value: 'rgb(250,173,77)',
      name: null,
      opacity: 1,
    }),
  },
  'widget-iconBackgroundType': {
    type: StyleParamType.Number,
    getDefaultValue: () => RssWidgetIconType.Square,
  },
});
